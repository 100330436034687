import React from "react"
import Layout from "../components/layouts/Layout"
import { InfoPagesWrapper } from "../components/layouts"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

const Hotel = () => {
  const data = useStaticQuery(graphql`
      query {
          hotel:file(relativePath: { regex: "/hotel.png/" }) {
              childImageSharp {
                  fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_tracedSVG
                  }
              }
          }
      }
  `)
  return (
    <Layout>
      <SEO title="Example 4, Hotel " />

      <InfoPagesWrapper>
        <h1>Hotel Mock-up Example</h1>
        <p>
          {`Although there aren't many tropical beaches here in Ireland, I thought
          I'd give something like this a go. Bright, refreshing, warming - these
          are the concepts I was thinking of while working on this one.`}
        </p>
      </InfoPagesWrapper>
      <div
        css={`
          width: 80%;
          margin: 2rem auto;

          img {
            margin: 2rem auto;

            //z-index: -100 !important;
          }
        `}
      >
        <Img fluid={data.hotel.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export default Hotel
